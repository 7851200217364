import React, { useEffect, useState } from "react";
import IAgoraRTC from "agora-rtc-react";
import useSound from 'use-sound';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';




// import soundUrl2 from '../src/assets/sound/a.mp3';

import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack
} from "agora-rtc-react";

import img1 from '../src/assets/images/icon-1.png'
import img2 from '../src/assets/images/icon-2.png'
import img3 from '../src/assets/images/icon-3.png'
import img4 from '../src/assets/images/icon-4.png'
import img5 from '../src/assets/images/icon-5.png'
import img6 from '../src/assets/images/icon-6.png'
import img7 from '../src/assets/images/icon-7.png'
import img8 from '../src/assets/images/icon-8.png'

import mic_on from '../src/assets/images/mic_on.png'
import mic_off from '../src/assets/images/mic_off.png'
import video_on from '../src/assets/images/videocam_on.png'
import video_off from '../src/assets/images/videocam_off.png'
import leave_ico from '../src/assets/images/leave_ico.png'
import soundUrl1 from '../src/assets/sound/b.mp3';
// import { eventNames } from "process";



// Requesting AUDIO permission only:
requestMediaPermissions()
	.then(() => {})
	.catch((err: MediaPermissionsError) => {

    const { type, name, message } = err;
    console.log("Step Name : "+name);
    console.log(message);
    if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // browser does not have permission to access camera or microphone
    } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        // user didn't allow app to access camera or microphone
    } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
    } else {
        // not all error types are handled by this library
    }

  });



// import Loader from '../src/assets/images/loader.gif'
// Enable log upload
IAgoraRTC.enableLogUpload();
// Set the log output level as INFO
IAgoraRTC.setLogLevel(1);

const config: ClientConfig = {
  mode: "rtc", codec: "vp8",
};

const urlApi: string = "https://vmapi.vmersive.com:3005/";

const appId: string = "ddb3579f01b44958b619d1d3fc54e6a4"; //ENTER APP ID HERE
let token: string | null = null;
var user_id_agora : string = '';
// let details: string | null = null;

// let gameSessionId: string | null = null;
var currentTimestamp = Math.floor(Date.now() / 1000);

function getToken<T>(url: string): Promise<T> {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
}


// function getGameSession<T>(url: string): Promise<T> {
//   return fetch(url)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error(response.statusText)
//       }
//       return response.json()
//     })
// }

var getImage = (val) =>{
  var path = '';
  switch(val){
    case 1:
      path = img1;
      break;
    case 2:
      path = img2;
      break;
    case 3:
      path = img3;
      break;
    case 4:
      path = img4;
      break;
    case 5:
      path = img5;
      break;
    case 6:
      path = img6;
      break;
    case 7:
      path = img7;
      break;
    case 8:
      path = img8;
      break;
  }
  return path;
}


const App = () => {
  //const [wrongUrl, setwrongUrl] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [name, setName] = useState('');
  const [showEye, setShowEye] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [metaFormPage, setMetaFormPage] = useState(1);
  const [avatar, setAvatar] = useState(1);
  // const [userTypeValue, setuserTypeValue] = useState('');
  const [eventName, setEventName] = useState(null);
  const [eventDescription, setEventDescription] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  // const [goToSettings, setGoToSettings] = useState(false);
  const [meetingId, setMeetingId] = useState('');
  const [userId, setUserId] = useState('');
  const [lobbyWaiting, setLobbyWaiting] = useState(0);
  const [lobby, setLobby] = useState([]);
  // const [goToLobby, setGoToLobby] = useState(false);

  // const [channelName, setChannelName] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  var meetingCode = String(queryParameters.get("i"));
  var userTypeId = String(queryParameters.get("u"));
  var projectId = String(queryParameters.get("projectId"));
  var modelId = String(queryParameters.get("modelId"));
  var version = String(queryParameters.get("version"));

  // if (userTypeId === '' && userTypeId === null) {
  //   //console.log('User Value:', userTypeId);
  //   setwrongUrl(false);
  //   // console.log("Done")
  // }
  

  // var changeUserType = (val) => {
  //   console.log(val);
  //   //setUserType(val);
  //   console.log(userType);
  //   console.log(name);
  //   console.log("Done")
  //   if (name !== '' && name !== null) {
  //     if (userType !== '' && userType !== null) {
  //       console.log("I am here");
  //       setGoToAvatar(true);
  //     }
  //   }
  // };


  var userTypeHost =() =>{
    if (name !== '' && name !== null) {
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"meeting_code": meetingCode,"passcode":passcode,"name":name,"type":userTypeId})
      };
      //fetch request
      fetch(''+ urlApi +'fe_meeting/login_m', requestOptions)
        .then(async response => {
      
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            console.log(data);
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                // const error = data.msg;
            } else {
              if(data.statusCode===200){
                setMeetingId(data.data.event_id);
                user_id_agora = data.data.user_id;
                setUserId(data.data.user_id);
                setCurrentPage(2);
              }
            }
        })
        .catch(error => {
            // this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
      }
  }
  var userTypePart =() =>{
   
    if (name !== '' && name !== null) {
        // POST request using fetch with error handling
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({"meeting_code": meetingCode,"passcode":passcode,"name":name,"type":userTypeId})
        };
        //fetch request
        fetch(''+ urlApi +'fe_meeting/login_m', requestOptions)
          .then(async response => {
        
              const isJson = response.headers.get('content-type')?.includes('application/json');
              const data = isJson && await response.json();
              console.log(data);
              // check for error response
              if (!response.ok) {
                  // get error message from body or default to response status
                  // const error = data.msg;
              } else {
                if(data.statusCode===200){
                  setMeetingId(data.data.event_id);
                  user_id_agora = data.data.user_id;
                  setUserId(data.data.user_id);
                  setCurrentPage(2);
                }
              }
          })
          .catch(error => {
              // this.setState({ errorMessage: error.toString() });
              console.error('There was an error!', error);
          });
    }
  }

  var getMeetingDetails = (valueMeetingCode) => {
    
    // POST request using fetch with error handling
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"event_code":valueMeetingCode})
    };
    fetch(''+ urlApi +'fe_meeting/getMeetingName', requestOptions)
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
            // console.log(data);
            // console.log(data.data.event_name);
            // console.log(data.data.event_description);
            setEventName(data.data.event_name);
            setEventDescription(data.data.event_description);

            var timeValue = '';
            var date;
            var fullValueTime;
            let date5=new Date(data.data.event_date_time).toString();
            //console.log(date5);
            // var weekday = date[0];
            // var day = date[1];
            // var month = date[2];
            // var year = date[3];
            date = date5.split(" ");
            // var time = date[4].split(":");
            // var hour = time[0];
            // var minute = time[1];
            // var second = time[2];

            var fulltime = date[4];
            
            timeValue = fulltime.split(/\r*\n|\n\r*|\r/).map(function(v) {
              return v ? '' + tConvert(v.trim()) + '' : v;
            }).join('\n');

            fullValueTime = 'Starts at - ' + timeValue + ', ' + '(' + date[5] +')';
            //fullValueTime = '' + date5 + '';
            
            setEventTime(fullValueTime);

            return true;
            }
        })
        .catch(error => {
            // this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
  }

  useEffect(() => {
    

    getMeetingDetails(meetingCode);

    
  });


  var tConvert = (time) =>{
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                  
      if (time.length > 1) { // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
  }


  var changeAvatar = (event) => {
    console.log(event.target.value);
    setAvatar(event.target.value);
  }
  

  const participantLobbyCheckInterval = ()=> {
  
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"user_id":userId,"event_id":meetingId})
    };
    //fetch request
    fetch(''+ urlApi +'fe_meeting/getCurrentLobbyStatus', requestOptions)
      .then(async response => {
    
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          console.log(data);
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              // const error = data.msg;
          } else {
            if(data.statusCode===200){
              if(data.data.lobby_status===1){
                //approve
                setInCall(true);

              } else if(data.data.lobby_status===2){
                //reject
              } 
             
            }
          }
      })
      .catch(error => {
          // this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });

}

// var secondsToTime = (secs) => {
//   let hours = Math.floor(secs / (60 * 60));

//   let divisor_for_minutes = secs % (60 * 60);
//   let minutes = Math.floor(divisor_for_minutes / 60);

//   let divisor_for_seconds = divisor_for_minutes % 60;
//   let seconds = Math.ceil(divisor_for_seconds);

//   let obj = {
//     "h": hours,
//     "m": minutes,
//     "s": seconds
//   };
//   return obj;
// }

// Set up the interval.
useEffect(() => {
  if(currentPage===4){ 
    let id = setInterval(participantLobbyCheckInterval, 2000);
    return () => clearInterval(id);
  } 
}, [currentPage]);


  // Set up the interval.
  useEffect(() => {
      if(inCall===true && userTypeId==='1'){ 
        let id = setInterval(allParticipantLobbyCheckIntervalFunction, 2000);
        return () => clearInterval(id);
      }
  }, [inCall,userTypeId]);

  var allParticipantLobbyCheckIntervalFunction = () => {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"event_id":meetingId})
    };
    //fetch request
    fetch(''+ urlApi +'fe_meeting/getAllPendingLobbyAdmit', requestOptions)
      .then(async response => {

          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          console.log(data);
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              // const error = data.msg;
          } else {
            if(data.statusCode===200){
              var res = data.data;
              var count = res.length;
              setLobbyWaiting(count);
              setLobby(res);
              console.log('count data:'+count);
              if(count>='1'){
                playSound1();
              }
            }
          }
      })
      .catch(error => {
          // this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });

  }

  const [playSound1] = useSound(soundUrl1);
  //const [playSound2] = useSound(soundUrl2);

  var acceptLobby = (user_id) => {
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"user_id":user_id,"event_id":meetingId})
      };
      //fetch request
      fetch(''+ urlApi +'fe_meeting/acceptUserInLobby', requestOptions)
        .then(async response => {

            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            console.log(data);
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                // const error = data.msg;
            } else {
              if(data.statusCode===200){
                allParticipantLobbyCheckIntervalFunction();
              }
            }
        })
        .catch(error => {
            // this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
  }

  var rejectLobby = (user_id) => {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"user_id":user_id,"event_id":meetingId})
    };
    //fetch request
    fetch(''+ urlApi +'fe_meeting/rejectUserInLobby', requestOptions)
      .then(async response => {

          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          console.log(data);
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              // const error = data.msg;
          } else {
            if(data.statusCode===200){
              allParticipantLobbyCheckIntervalFunction();
            }
          }
      })
      .catch(error => {
          // this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
  }



  var submitAvatar = () => {

        // POST request using fetch with error handling
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({"avatar": avatar,"user_id":userId,"event_id":meetingId})
        };
        //fetch request
        fetch(''+ urlApi +'fe_meeting/updateAvatar', requestOptions)
          .then(async response => {
        
              const isJson = response.headers.get('content-type')?.includes('application/json');
              const data = isJson && await response.json();
              console.log(data);
              // check for error response
              if (!response.ok) {
                  // get error message from body or default to response status
                  // const error = data.msg;
              } else {
                if(data.statusCode===200){
                  if(userTypeId==='2'){
                    setMetaFormPage(2);
                    setCurrentPage(4);
                  } else {
                    setInCall(true);
                  }
                }
              }
          })
          .catch(error => {
              // this.setState({ errorMessage: error.toString() });
              console.error('There was an error!', error);
          });


    
    // getGameSession('http://localhost:3005/aws/createMeetingSession?channelName='+meetingId)
    // getGameSession('https://vmapi.vmersive.com:3005/aws/createMeetingSession?channelName=' + meetingId)
    //   .then(function (response) {
    //     console.log(response);
    //     var data = { 'key': '' };
    //     data = (response as any);
    //     console.log(data);
    //     gameSessionId = data.key;
    //setGoToSettings(true);
   
    //setInCall(true);

    // }
    // );
    // console.log("gameSessionId="+gameSessionId);
  }
  

  if (inCall) {
    //  getToken('http://localhost:3005/agora/rtcToken?channelName='+meetingId)
    getToken(urlApi+'agora/rtcToken?channelName=' + meetingId)
      .then(function (response) {
        var data = { 'key': '' };
        data = (response as any);
        token = data.key;
      }
      );
    console.log(token);
    
    fetchUsersDetails(meetingId);
  }
  // const pureweb_url = "http://localhost:3002/?UID="+currentTimestamp;
  // const pureweb_url = "https://localhost:3002/?UID=" + currentTimestamp + '&channelName=' + meetingId+'&userType='+userType+'&name='+name+'&avatar='+avatar+'&gameSessionId='+gameSessionId;
  
  const pureweb_url = "https://pw-test.vmersive.com/?UID=" + currentTimestamp + '&channelName=' + meetingCode + '&userType=' + userTypeId + '&name=' + name + '&avatar=' + avatar+'&projectId='+projectId+'&modelId='+modelId+'&version='+version;
  return (
    <div>

      {inCall ?
        (
          <>
              
            <div className="player-list-screen">
              <div className="main-screen">
                <iframe src={pureweb_url} title="video" className='main_iframe' allow='camera;microphone'></iframe>
              </div>
              <div className="sidebar">
                <div className="meeting-info">
                  <div className="meeting-title" title="">{eventName}</div>
                  <div className="time-wrapper d-none">26:32</div>
                </div>
                <div className="meeting-user-list">
                  <div className={`waiting-list ${(userTypeId==='1') ? '' : 'd-none'} ${(lobbyWaiting===0) ? 'd-none' : ''}`}>
                    <div className={`title ${(lobbyWaiting===0) ? 'd-none' : ''}`}>Waiting in lobby ({lobbyWaiting})</div>
                    <div className={`waiting-list-wrap ${(lobbyWaiting===0) ? 'd-none' : ''}`}>
                      <div className="waiting-list-inner">

                      {lobby.map((row) => (  
                        <div className="inner-wrap">
                          <div className="icon-wrap">
                            <img src={getImage(row['avatar'])} alt="" />
                          </div>
                          <div className="media-body">{row['name']}</div>
                          <div className="action-btn">
                            <button className="accept action" onClick={()=>acceptLobby(row['user_id'])}>
                              <svg width={13} height={10} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 4.99984L4.53742 8.53734L11.6248 1.46234" stroke="#3CCF4E" strokeWidth="1.49998" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                            <button className="reject action"  onClick={()=>rejectLobby(row['user_id'])}>
                              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.45312 8.53728L8.52812 1.46228" stroke="#ED3E3E" strokeWidth="1.49998" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.52812 8.53728L1.45312 1.46228" stroke="#ED3E3E" strokeWidth="1.49998" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))}  

                      </div>
                    </div>
                  </div>

                  <VideoCall setInCall={setInCall} channelName={meetingId} />

                </div>
              </div>

          </div>
            </>
        ) : (
          <>
            {/* <div className="d-none">
              <h1 className="heading">Ready to join?</h1>
              <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
            </div> */}

            <div className={`meta-form-wrapper ${(metaFormPage===1) ? '' : 'd-none'}`}> {/* Add class loading when page is loading */}

              <div className="bg-view">
                <video muted loop autoPlay>
                  <source src="https://vm-fixed-asset.s3.eu-central-1.amazonaws.com/vmersive-bg.mp4" type="video/mp4" />

                </video>
              </div>

              <div className="time-wrapper">
                {/* <p>Starts at - 03:00 PM, (GMT+05:30)</p> */}
                <p>{eventTime}</p>
              </div>

              <div className={`form-wrapper ${(currentPage!==1) ? 'd-none' : ''}`}>
                <div className="heading">
                  <h2>{eventName}</h2>
                  <p>{eventDescription}</p>
                </div>
                <div className="form-field-wrapper">
                  <div className={`mb__20 ${userTypeId === '1' ? 'd-none' : '' ||  userTypeId === '2' ? 'd-none' : ''}`}>
                    <h2>Requested page does not exist</h2>
                  </div>
                  <div className={`mb__20 ${userTypeId === '1' ? '' : 'd-none' &&  userTypeId === '2' ? '' : 'd-none'}`}>
                    <label className="text">Name</label>
                    <input type="text" name="" className="form-control" placeholder="Type your name here" onInput={e => setName((e.target as HTMLInputElement).value)} />
                  </div>
                  <div className={`mb__20 ${userTypeId === '1' ? '' : 'd-none' &&  userTypeId === '2' ? '' : 'd-none'}`}>
                    <label className="text">Meeting passcode</label>
                    <div className="position-relative">
                      <input type={showEye ? "text" : "password"} name="" className="form-control" placeholder="Meeting Passcode" onInput={e => setPasscode((e.target as HTMLInputElement).value)} />
                      <span className={`forgot-wrap ${showEye ? "password" : "text" }`} onClick={() => {setShowEye(!showEye); }}/>
                      {/* Remove Class text once this is clicked and change inout type password to text */}
                    </div>

                  </div>

                  <div className="check-user-wrap d-block">

                    <div className={`form-check full-width m-0 ${userTypeId === '2'  ? '' : 'd-none'}`}>
                      {/* <input className="form-check-input" type="radio" id="userType1" name="userType" onClick={() => changeUserType('2')} value="2" />
                      <label className="form-check-label" htmlFor="userType1">
                        Ask to join?
                      </label> */}
                      <button className="btn btn-theme" onClick={() => userTypePart()}> Join meeting</button>
                    </div>

                    <div className={`form-check full-width m-0 ${userTypeId === '1'  ? '' : 'd-none'}`}>
                      {/* <input className="form-check-input" type="radio" id="userType2" name="userType" onClick={() => changeUserType('1')} value="1" />
                      <label className="form-check-label" htmlFor="userType2">
                        Presenter
                      </label> */}
                      <button className="btn btn-theme" onClick={() => userTypeHost() }>
                        Start your meeting
                      </button>
                    </div>

                  </div>
                </div>

              </div>

              <div className={`form-wrapper select-user-icon ${(currentPage===2) ? '' : 'd-none'} `}>
                <div className="heading">
                  <h2>Choose your avatar</h2>
                </div>
                <div className="user-icon-wrapper">
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon1" onChange={e => changeAvatar(e)} value="1" />
                      <label className="form-check-label" htmlFor="Icon1">
                        <img src={img1} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon2" onChange={e => changeAvatar(e)} value="2" />
                      <label className="form-check-label" htmlFor="Icon2">
                        <img src={img2} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon3" onChange={e => changeAvatar(e)} value="3" />
                      <label className="form-check-label" htmlFor="Icon3">
                        <img src={img3} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon4" onChange={e => changeAvatar(e)} value="4" />
                      <label className="form-check-label" htmlFor="Icon4">
                        <img src={img4} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon5" onChange={e => changeAvatar(e)} value="5" />
                      <label className="form-check-label" htmlFor="Icon5">
                        <img src={img5} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon6" onChange={e => changeAvatar(e)} value="6" />
                      <label className="form-check-label" htmlFor="Icon6">
                        <img src={img6} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon7" onChange={e => changeAvatar(e)} value="7" />
                      <label className="form-check-label" htmlFor="Icon7">
                        <img src={img7} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className="icon-column">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="selectIcon" id="Icon8" onChange={e => changeAvatar(e)} value="8" />
                      <label className="form-check-label" htmlFor="Icon8">
                        <img src={img8} alt="" />
                      </label>
                    </div>
                  </div>
                </div>


                <div className="button-wrap">
                  <button className="btn btn-theme" onClick={submitAvatar}>Start your experience</button>
                </div>


              </div>

              <div className={`form-wrapper setting-main-wrapper ${(currentPage===3) ? '' : 'd-none'}`}>
                
                <div className="heading">
                  <h2>Confirm your settings</h2>
                  <p>Get your camera and microphone ready! You’re about to embark on a truly unique experience.</p>
                </div>
                <div className="form-field-wrapper">
                  <div className="mb__20 camera-mic-setting">
                    <div className="camera-setting setting-box">
                      <div className="setting-inner">
                        {/* <img src={imgCamera} alt="" /> */}
                        <div className="icon-wrap">
                          camera


                        </div>
                      </div>
                      <div className="action-btn">
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>

                        <div className="dropdown-wrap">
                          <select className="form-select">
                            <option>Integrated camera</option>
                            <option value={1}>Camera 1</option>
                            <option value={2}>Camera 2</option>
                            <option value={3}>Camera 3</option>
                          </select>

                        </div>
                      </div>
                    </div>
                    <div className="mic-setting setting-box active">
                      <div className="setting-inner">
                        <div className="icon-wrap">
                          mic


                          <div className="circle delay1" />
                          <div className="circle delay2" />
                          <div className="circle delay3" />



                        </div>
                      </div>
                      <div className="action-btn">
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>

                        <div className="dropdown-wrap">
                          <select className="form-select">
                            <option>Microphone</option>
                            <option value={1}>Microphone 1</option>
                            <option value={2}>Microphone 2</option>
                            <option value={3}>Microphone 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check-user-wrap">
                    <div className="form-check">

                      <button className="btn btn-theme"> Continue </button>
                    </div>

                  </div>
                </div>

              </div>

              

              <div className="portrait-view-wrapper d-none">
                <div className="portrait-inner">
                  <div className="icon-wrap">
                    <svg width={168} height={168} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.125 5.25H18.375C14.8942 5.25 11.5555 6.63305 9.09398 9.09398C6.63306 11.5555 5.25 14.8942 5.25 18.375V149.625C5.25 153.106 6.63305 156.445 9.09398 158.906C11.5555 161.367 14.8942 162.75 18.375 162.75H76.125C79.6058 162.75 82.9445 161.367 85.406 158.906C87.8669 156.445 89.25 153.106 89.25 149.625V18.375C89.25 14.8942 87.867 11.5555 85.406 9.09398C82.9445 6.63306 79.6058 5.25 76.125 5.25ZM84 149.618C84 151.707 83.1704 153.71 81.6932 155.186C80.2166 156.663 78.2135 157.493 76.125 157.493H18.375C16.2865 157.493 14.2832 156.663 12.8068 155.186C11.3296 153.71 10.5 151.707 10.5 149.618V18.368C10.5 16.2795 11.3296 14.2762 12.8068 12.7998C14.2834 11.3226 16.2865 10.493 18.375 10.493H26.25V13.118C26.25 13.8142 26.5267 14.4819 27.0189 14.9741C27.5111 15.4663 28.1788 15.743 28.875 15.743H65.625C66.3212 15.743 66.9889 15.4663 67.4811 14.9741C67.9733 14.4819 68.2501 13.8142 68.2501 13.118V10.493H76.1251C78.2136 10.493 80.2168 11.3226 81.6933 12.7998C83.1704 14.2764 84.0001 16.2795 84.0001 18.368L84 149.618Z" fill="black" />
                      <path d="M149.632 78.75H99.7567C98.8188 78.75 97.9525 79.2504 97.4833 80.0625C97.0147 80.8746 97.0147 81.8754 97.4833 82.6875C97.9526 83.4996 98.8188 84 99.7567 84H149.632C151.72 84 153.723 84.8296 155.2 86.3068C156.677 87.7834 157.507 89.7865 157.507 91.875V99.75H154.882C154.185 99.75 153.518 100.027 153.026 100.519C152.533 101.011 152.257 101.679 152.257 102.375V139.125C152.257 139.821 152.533 140.489 153.026 140.981C153.518 141.473 154.185 141.75 154.882 141.75H157.507V149.625C157.507 151.714 156.677 153.717 155.2 155.193C153.723 156.67 151.72 157.5 149.632 157.5H94.5067C93.5688 157.5 92.7025 158 92.2333 158.813C91.7647 159.625 91.7647 160.625 92.2333 161.438C92.7026 162.25 93.5688 162.75 94.5067 162.75H149.632C153.112 162.75 156.451 161.367 158.913 158.906C161.374 156.445 162.757 153.106 162.757 149.625V91.8751C162.757 88.3942 161.374 85.0555 158.913 82.594C156.451 80.1331 153.112 78.7501 149.632 78.7501V78.75Z" fill="black" />
                      <path d="M105.525 35.7001C106.026 36.3547 106.801 36.7419 107.625 36.7501C108.194 36.7539 108.748 36.569 109.2 36.2251C109.757 35.8072 110.126 35.1854 110.224 34.4963C110.323 33.8072 110.143 33.1067 109.725 32.5501L105.578 26.9851C114.806 28.5595 123.4 32.713 130.368 38.9649C137.336 45.2173 142.392 53.3135 144.953 62.3169L137.813 58.1169C137.213 57.6799 136.455 57.518 135.728 57.6712C135.001 57.8243 134.373 58.2782 134 58.9202C133.627 59.5628 133.542 60.3328 133.769 61.0405C133.996 61.7476 134.511 62.3262 135.188 62.6319L146.475 69.2994C146.87 69.5428 147.325 69.6702 147.788 69.6669H148.47C149.15 69.522 149.729 69.0785 150.045 68.4594L156.713 57.1719C157.065 56.5709 157.168 55.8561 157.002 55.1797C156.835 54.5032 156.411 53.9186 155.82 53.5494C155.213 53.2021 154.494 53.107 153.817 53.2836C153.14 53.4603 152.558 53.895 152.198 54.4944L149.521 59.0619C146.433 49.5342 140.792 41.0369 133.211 34.4919C125.629 27.9469 116.398 23.6075 106.522 21.9444L111.772 17.9544C112.523 17.3917 112.916 16.4712 112.804 15.5405C112.691 14.6092 112.091 13.8086 111.229 13.4405C110.366 13.0719 109.373 13.1917 108.622 13.7544L98.1224 21.6294C97.5657 22.0472 97.1971 22.669 97.0986 23.3582C97.0002 24.0473 97.1796 24.7478 97.5974 25.3044L105.525 35.7001Z" fill="#3CCF4E" />
                    </svg>
                  </div>
                  <div className="heading">
                    <h2>Rotate your phone</h2>
                    <p>For better Vmersive experience</p>
                  </div>
                </div>
              </div>

            </div>

            <div className={`meeting-lobby-screen   ${(currentPage===4) ? '' : 'd-none'}`}>
                <div className="header">
                  <div className="logo-wrap">
                    <svg width={285} height={46} viewBox="0 0 285 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M46.7516 16.2689C49.4731 14.0631 52.6413 13.189 56.2529 13.6501C59.9156 14.1388 62.753 15.8834 64.7617 18.8876C66.2623 16.6302 68.2982 15.0644 70.8662 14.1904C73.3319 13.3679 75.8283 13.306 78.3451 13.9977C80.9404 14.741 83.0241 16.1794 84.6031 18.3095C86.3833 20.6701 87.2734 23.5986 87.2734 27.0879V44.3385H80.33V27.0879C80.33 25.2125 79.682 23.6226 78.3826 22.3116C77.0833 21.0039 75.5214 20.3466 73.6866 20.3466C71.8518 20.3466 70.2524 21.0005 68.953 22.3116C67.6571 23.6192 67.0057 25.2125 67.0057 27.0879V44.3385H60.0998V27.0879C60.0998 25.2125 59.4518 23.6226 58.1524 22.3116C56.8292 21.0039 55.2536 20.3466 53.4189 20.3466C51.5841 20.3466 50.0085 21.0005 48.6853 22.3116C47.4132 23.5951 46.7653 25.1471 46.738 26.9709V44.3351H39.832V14.0734H46.738V16.2689H46.7516Z" fill="white" />
                      <path d="M127.763 38.4473C124.25 42.8107 119.725 44.9924 114.179 44.9924C112.143 44.9924 110.158 44.6448 108.225 43.9532C106.291 43.2856 104.562 42.322 103.034 41.066C99.2692 37.9862 97.3867 34.0323 97.3867 29.2077C97.3867 24.9475 98.8873 21.2999 101.888 18.2717C104.992 15.1402 108.77 13.5745 113.221 13.5745C117.671 13.5745 121.413 15.1402 124.516 18.2717C126.371 20.4534 127.619 22.6213 128.254 24.7789C128.762 26.5236 129.018 28.898 129.018 31.9022H103.87C104.354 33.8017 105.357 35.3812 106.885 36.6372C108.31 37.7659 109.988 38.4851 111.922 38.7948C113.855 39.077 115.738 38.8981 117.569 38.2546C119.503 37.5629 121.106 36.4308 122.378 34.865L127.759 38.4473H127.763ZM107.423 21.5821C105.797 22.7899 104.664 24.3557 104.027 26.2793H122.037C121.552 24.4073 120.407 22.8278 118.603 21.5442C116.873 20.3123 115.015 19.6963 113.03 19.6963C111.045 19.6963 109.101 20.3261 107.42 21.5821H107.423Z" fill="white" />
                      <path d="M157.79 21.3138C154.076 19.6964 150.946 20.1713 148.402 22.7384C146.85 24.3042 146.059 26.1899 146.035 28.3992V44.3387H139.129V14.0735H146.035V18.2718C148.78 13.2649 153.616 12.1637 160.536 14.9614L157.787 21.3138H157.79Z" fill="white" />
                      <path d="M182.529 19.9266C181.69 19.3864 180.712 19.1455 179.59 19.1936C178.444 19.2177 177.492 19.5412 176.728 20.1572C175.889 20.8489 175.47 21.7504 175.47 22.8516C175.47 23.8289 176.104 24.6754 177.376 25.3947C178.164 25.8042 179.576 26.3444 181.612 27.012C185.019 28.0891 187.362 29.0664 188.634 29.937C190.745 31.3995 191.802 33.3885 191.802 35.904C191.802 38.7292 190.591 41.0004 188.177 42.721C185.987 44.2626 183.303 45.0713 180.125 45.147C176.943 45.1986 174.211 44.4932 171.92 43.0307C169.399 41.4133 168.052 39.1938 167.875 36.3686L174.781 36.1001C174.832 37.2048 175.391 38.0788 176.459 38.7189C177.427 39.3108 178.584 39.5929 179.931 39.5654C181.278 39.5413 182.41 39.2179 183.327 38.6019C184.344 37.9343 184.855 37.0361 184.855 35.9074C184.855 34.6755 182.949 33.4573 179.129 32.2495C175.62 31.1208 173.188 30.0919 171.841 29.1696C169.652 27.628 168.56 25.5254 168.56 22.8551C168.56 20.1847 169.655 17.8481 171.841 16.1551C173.826 14.5894 176.282 13.7428 179.208 13.6121C182.134 13.4847 184.688 14.1007 186.877 15.46C189.268 16.9741 190.656 19.1042 191.038 21.8502L184.17 22.4662C184.016 21.3616 183.471 20.5151 182.529 19.9232V19.9266Z" fill="white" />
                      <path d="M209.089 1.48194C209.914 2.30438 210.33 3.30576 210.33 4.48609C210.33 5.66641 209.917 6.66779 209.089 7.49024C208.263 8.31268 207.274 8.72218 206.132 8.72218C204.989 8.72218 203.97 8.31268 203.154 7.49024C202.339 6.66779 201.934 5.66641 201.934 4.48609C201.934 3.30576 202.339 2.30438 203.154 1.48194C203.97 0.659501 204.962 0.25 206.132 0.25C207.301 0.25 208.263 0.659501 209.089 1.48194Z" fill="white" />
                      <path d="M209.64 14.073H202.734V44.3381H209.64V14.073Z" fill="white" />
                      <path d="M283.134 38.4473C279.621 42.8107 275.096 44.9924 269.55 44.9924C267.514 44.9924 265.53 44.6448 263.596 43.9532C261.662 43.2856 259.933 42.322 258.405 41.066C254.64 37.9862 252.758 34.0323 252.758 29.2077C252.758 24.9475 254.258 21.2999 257.259 18.2717C260.363 15.1402 264.142 13.5745 268.592 13.5745C273.043 13.5745 276.784 15.1402 279.887 18.2717C281.742 20.4534 282.991 22.6213 283.625 24.7789C284.133 26.5236 284.389 28.898 284.389 31.9022H259.241C259.725 33.8017 260.728 35.3812 262.256 36.6372C263.681 37.7659 265.359 38.4851 267.293 38.7948C269.226 39.077 271.109 38.8981 272.94 38.2546C274.874 37.5629 276.477 36.4308 277.749 34.865L283.13 38.4473H283.134ZM262.794 21.5821C261.168 22.7899 260.036 24.3557 259.398 26.2793H277.408C276.924 24.4073 275.778 22.8278 273.974 21.5442C272.245 20.3123 270.386 19.6963 268.401 19.6963C266.416 19.6963 264.472 20.3261 262.791 21.5821H262.794Z" fill="white" />
                      <path d="M249.894 14.073H241.262L227.433 32.6966V14.073H220.527V44.3381H227.433V44.3209L249.894 14.073Z" fill="#0CE50C" />
                      <path d="M0.46875 14.1281L8.0943 14.073L15.9415 33.9492L23.6216 14.073H30.9675L19.5292 44.3381H12.0162L0.46875 14.1281Z" fill="white" />
                    </svg>
                  </div>
                </div>
                <div className="media-body text-body">
                  <div className="text-wrap text-center">
                    <div className="subtitle">{eventName}</div>
                    <h2>Please wait, the meeting host will let you in soon.</h2>
                    {/* <p>03:00 PM, (GMT+05:30) Chennai, Kolkata</p> */}
                    <p>{eventTime}</p>
                  </div>
              </div>
            </div>

            {/* <div className="sketchfab-embed-wrapper">
              <iframe title="Manufacturing" frameBorder={0} allowFullScreen allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/a852f567976d4a87804001afb15320a9/embed"> </iframe>
            </div> */}

            {/* <div className="loader-wrap">
              <div className="loader-inner">
                <div className="icon-wrap">
                <img src={Loader} alt="" />
                </div>
                <p>Please wait, your session is loading. </p>
              </div>
            </div> */}
          </>
        )}
    </div>
  );
};



// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
var nameagora = [];
var nameagora_map = [];


const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  channelName: string;
}) => {
  console.log(props);
  const { setInCall, channelName } = props;
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [start, setStart] = useState<boolean>(false);

    
  // const queryParameters = new URLSearchParams(window.location.search);
  // var meetingCode = String(queryParameters.get("i"));
  // var userTypeId = String(queryParameters.get("u"));

  // using the hook to get access to the client object
  const client = useClient();
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  // const [playSound2] = useSound(soundUrl2);




  useEffect(() => {
    
    
    // function to initialise the SDK
    let init = async (name: string) => {
      fetchUsersDetails(channelName);
      client.on("user-published", async (user, mediaType) => {
        console.log("User Agora "+user);
        console.log(user);
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        console.log(client);

        if (mediaType === "video") {
          setUsers((prevUsers) => {
            prevUsers = prevUsers.filter((User) => User.uid !== user.uid);
            return [...prevUsers, user];
          });
          //play();
        }
        if (mediaType === "audio") {
          //user.audioTrack?.play();
          user.audioTrack?.play();
          // setUsers((prevUsers) => {
          //   //prevUsers = prevUsers.filter((User) => User.uid !== user.uid);
          //   return [...prevUsers, user];
          // });
        }
      });


      client.on("user-joined",(user)=>{
        console.log("Step joined");
        console.log("Step joined" + user);
        
        fetchUsersDetails(channelName);
        //playSound2();
      
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          // setUsers((prevUsers) => {
          //   console.log("Step 2 : " + user.uid);
          //   return prevUsers = prevUsers.filter((User) => User.uid !== user.uid);
          // });
          setUsers((prevUsers) => {
            prevUsers = prevUsers.filter((User) => User.uid !== user.uid);
            return [...prevUsers, user];
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("Step 3 : " + user.uid);
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
      console.log("user_id_agora : "+ user_id_agora);
      await client.join(appId, name, token, user_id_agora);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);

    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }

  }, [channelName, client, ready, tracks]);


  return (
    <>
      {ready && tracks && (
        <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
      )}
      {start && tracks && <Videos users={users} tracks={tracks} />}
    </>
  );
};


var fetchUsersDetails = (meetingId) => {
  // POST request using fetch with error handling
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({"event_id":meetingId})
  };
  //fetch request
  fetch(''+ urlApi +'fe_meeting/fetchUsersDetails', requestOptions)
    .then(async response => {

        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        console.log(data);
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            // const error = data.msg;
        } else {
          if(data.statusCode===200){
            nameagora = data.data;
            console.log(nameagora);
            for(var i=0;i<nameagora.length;i++){
              nameagora_map[nameagora[i]["user_id"]] = nameagora[i];
            }
            //console.log(nameagora_map["0d0bc190-9e2b-11ed-a92b-d191648861e6"].name);
          }
        }
    })
    .catch(error => {
        // this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
    });
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[];
  tracks: { "0": IMicrophoneAudioTrack, "1": ICameraVideoTrack };
}) => {
  const { users, tracks } = props;
  console.log("Video step 1");
  console.log(users);
  const queryParameters = new URLSearchParams(window.location.search);
  //var meetingCode = String(queryParameters.get("i"));
  var userTypeId = String(queryParameters.get("u"));


  return (

    <div className='videos_box user-list-wrapper'>
      <div id="videos" className="user-list">
        {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}

            
        {users.length > 0 &&
          users.map((user) => {
            if (user.hasVideo) {
              if(user.videoTrack){
              return (

                <div className="user-list-inner">
                  <button className={`${(user.hasAudio) ? 'action d-none single-mic' : 'action mic-btn single-mic'}`} >mic</button>
                  <div className="user-video-wrap">
                    <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} key={user.uid} />

                    <div className="text-wrap">
                      <div className="name"> {nameagora_map[user.uid].name}</div>
                      
                    </div>

                  </div>
                </div>

              );
              }
            } else return (
              <>
              {/* if the video is not on, user will see this div  */}

                <div className="user-list-inner">
                  {/** if this user is speaking add class active to its parent and his mic is already on so unmuted icon is hidden, if his mic is umted, show muted icon */}

                  <button className={`${(user.hasAudio) ? 'action d-none single-mic' : 'action mic-btn single-mic'}`}>mic</button>

                  <div className="user-icon-wrap">
                    <div className="img-wrap">
                      <img src={getImage(nameagora_map[user.uid].avatar)} alt="" />
                    </div>
                    <div className="text-wrap">
                      <div className="name">{nameagora_map[user.uid].name}</div>

                    </div>
                  </div>
                </div>
              </>
            );
          })
        }

        <div className="user-host">
          <div className="user-list-inner">
            <div className="user-video-wrap">
              <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
              <div className="text-wrap">
                <div className="name">You <i className={`${userTypeId === '1' ? '' : 'd-none'}`}>(Host)</i></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


export const Controls = (props: {
  tracks: { "0": IMicrophoneAudioTrack, "1": ICameraVideoTrack };
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    // we close the tracks to perform cleanup
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls action-btns">
      <div className={trackState.audio ? "action mic-btn on" : "action mic-btn off"}
        onClick={() => mute("audio")}>
        <img className="d-none" src={trackState.audio ? mic_on : mic_off} alt="" />
        <span>{trackState.audio ? "Mic" : "Mic"}</span>
      </div>
      <div className={trackState.video ? "action camera-btn on" : "action camera-btn off"}
        onClick={() => mute("video")}>
        <img className="d-none" src={trackState.video ? video_on : video_off} alt="" />
        <span>{trackState.video ? "Camera" : "Camera"}</span>
      </div>
      {<div className='leave_cont d-none' onClick={() => leaveChannel()}> <img src={leave_ico} alt="" /> Leave</div>}
    </div>
  );
};



// const ChannelForm = (props: {
//   setInCall: React.Dispatch<React.SetStateAction<boolean>>;
//   setChannelName: React.Dispatch<React.SetStateAction<string>>;
// }) => {
//   const { setInCall, setChannelName } = props;
//   return (
//     <form className="join">
//       {appId === '' && <p style={{ color: 'red' }}>Please enter your Agora App ID in App.tsx and refresh the page</p>}
//       <input type="text"
//         placeholder="Enter Meeting ID"
//         onChange={(e) => setChannelName(e.target.value)}
//       />
//       <button onClick={(e) => {
//         e.preventDefault();
//         setInCall(true);
//       }}>
//         Join
//       </button>
//     </form>
//   );
// };



export default App;
